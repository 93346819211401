import {
	getDefaultItemPayload,
	getTags,
	getTitle,
	renderToStaticMarkup,
	setDescription,
	setLinks,
	setMetaTags,
	setSchemas,
	setTitle,
	setExternalRouter,
} from '@wix/advanced-seo-utils/renderer'
import { RouteInfo } from 'feature-router'

export {
	getTags,
	getDefaultItemPayload,
	getTitle,
	setTitle,
	setLinks,
	setSchemas,
	setMetaTags,
	setDescription,
	setExternalRouter,
}

export const setWindowTitle = (title: string) => {
	if (process.env.browser) {
		window.document.title = title
	}
}

export const getStaticMarkup = (tags: any) => renderToStaticMarkup(tags).join('\n')

export const convertDynamicPageModel = async (pageHeadData: any) => {
	const converters = await import('@wix/advanced-seo-utils/converters' /* webpackChunkName: "seo-api-converters" */)
	return converters.convertDynamicPageModel(pageHeadData)
}
export const convertTPAEndpointModel = async (tpaEnapointData: any) => {
	const converters = await import('@wix/advanced-seo-utils/converters' /* webpackChunkName: "seo-api-converters" */)
	return converters.convertTpaModel(tpaEnapointData)
}
export const resolveDynamicPageHeadData = async (
	payload: RouteInfo['dynamicRouteData'] | undefined
): Promise<RouteInfo['dynamicRouteData'] | undefined> => {
	if (payload) {
		const { resolveMetaTags } = await import(
			'./resolve-meta-tags' /* webpackChunkName: "seo-api-resolveMetaTags" */
		)
		const { pageHeadData = {} } = payload
		return {
			...pageHeadData,
			metaTags: resolveMetaTags(pageHeadData.metaTags || {}),
		}
	}
}
